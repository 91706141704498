// src/pages/ContactPage.js
import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import './ContactPage.css';
import Navigation from '../common/navbar/Navigation';
import Footer from '../common/footer/Footer';

const ContactPage = () => {
    const formRef = useRef();

    // useEffect(() => {
    //     gsap.from(formRef.current, {
    //         opacity: 0,
    //         y: 50,
    //         duration: 1,
    //     });
    // }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        alert('Form submitted!');
    };

    return (
        <>
            <Navigation/>
            <div className="contact-page">
                <header className="contact-hero">
                    <h1>Contact Us</h1>
                    <p>We're here to help. Reach out to us with any questions or inquiries!</p>
                    <img src="/images/contact-background.jpg" alt="Contact background" className="contact-background-img" />
                </header>

                {/* Company Information Section */}
                <section className="company-info">
                    <h2>About Techveda</h2>
                    <p>
                        At Techveda, we’re dedicated to providing top-notch software solutions that drive business success. 
                        Our team of experts is passionate about helping you achieve your goals with personalized support 
                        and cutting-edge technology.
                    </p>
                </section>

                {/* Quick Contact Details */}
                <section className="quick-contact">
                    <div className="contact-item">
                        <i className="pi pi-envelope"></i>
                        <p>Email: support@techveda.org.in</p>
                    </div>
                    <div className="contact-item">
                        <i className="pi pi-phone"></i>
                        <p>Phone: +91 8141948581 / +91 9737184225</p>
                    </div>
                    {/* <div className="contact-item">
                        <i className="pi pi-map-marker"></i>
                        <p>Address: 123 Tech Street, Silicon Valley, CA</p>
                    </div> */}
                </section>

                {/* Contact Form */}
                <section className="contact-form-section">
                    <h2>Get in Touch</h2>
                    <form ref={formRef} onSubmit={handleSubmit} className="contact-form">
                        <label htmlFor="name">Name</label>
                        <input type="text" id="name" name="name" required placeholder="Enter your name" />

                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" name="email" required placeholder="Enter your email" />

                        <label htmlFor="phone">Phone</label>
                        <input type="tel" id="phone" name="phone" placeholder="Enter your phone number" />

                        <label htmlFor="message">Message</label>
                        <textarea id="message" name="message" rows="5" required placeholder="Enter your message"></textarea>

                        <button type="submit">Send Message</button>
                    </form>
                </section>
            </div>
            <Footer/>
        </>
    );
};

export default ContactPage;
