// src/pages/ServicesPage.js
import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './ServicesPage.css';
import Navigation from '../common/navbar/Navigation';
import Footer from '../common/footer/Footer';
import { Link } from 'react-router-dom';

gsap.registerPlugin(ScrollTrigger);

const ServicesPage = () => {
    const serviceRef = useRef([]);
    const addToRefs = (el) => {
        if (el && !serviceRef.current.includes(el)) {
            serviceRef.current.push(el);
        }
    };

    useEffect(() => {
        serviceRef.current.forEach((section, index) => {
            gsap.fromTo(
                section,
                { opacity: 0, y: 50 },
                {
                    opacity: 1,
                    y: 0,
                    duration: 1,
                    scrollTrigger: {
                        trigger: section,
                        start: 'top 80%',
                        end: 'bottom 60%',
                        toggleActions: 'play none none reverse',
                    },
                }
            );
        });
    }, []);

    return (
        <>
            <Navigation/>
            <div className="services-page">
                <header className="hero-section">
                    <h1>Our Services</h1>
                    <p>Empowering Your Vision with Cutting-Edge Solutions</p>
                </header>
                
                <section className="service-category" ref={addToRefs}>
                    <h2>Custom Software Development</h2>
                    <p>From concept to deployment, we create robust, scalable solutions tailored to your needs.</p>
                    <Link to="/services/custom-software-development" className="learn-more-link">Learn More</Link>
                </section>
                
                <section className="service-category" ref={addToRefs}>
                    <h2>Cloud Solutions</h2>
                    <p>Boost efficiency and scalability with secure cloud infrastructure and expert support.</p>
                    <Link to="/services/cloud-solutions" className="learn-more-link">Learn More</Link>
                </section>
                
                <section className="service-category" ref={addToRefs}>
                    <h2>Inventory Management Systems</h2>
                    <p>Transform your inventory management with real-time data and insightful analytics.</p>
                    <Link to="/services/inventory-management" className="learn-more-link">Learn More</Link>
                </section>
                
                <section className="service-category" ref={addToRefs}>
                    <h2>Business Loan Audit Systems</h2>
                    <p>Streamline audits and enhance compliance with our custom loan audit solutions.</p>
                    <Link to="/services/business-loan-audit" className="learn-more-link">Learn More</Link>
                </section>
                
                <section className="cta-section">
                    <h2>Ready to transform your business?</h2>
                    <Link to="/contact" className="button">Contact Us</Link>
                </section>
            </div>
            <Footer/>
        </>
    );
};

export default ServicesPage;
