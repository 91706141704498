import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './About.css';
import Navigation from '../common/navbar/Navigation';
import Footer from '../common/footer/Footer';

gsap.registerPlugin(ScrollTrigger);

const About = () => {
    const introRef = useRef(null);
    const journeyRef = useRef(null);
    const teamRef = useRef(null);
    const visionRef = useRef(null);
    const imageRef = useRef([]);

    useEffect(() => {
        const sections = [introRef, journeyRef, teamRef, visionRef];
        
        sections.forEach((section) => {
            gsap.fromTo(
                section.current,
                { opacity: 0, y: 50 },
                {
                    opacity: 1,
                    y: 0,
                    duration: 2,
                    ease: 'power3.out',
                    scrollTrigger: {
                        trigger: section.current,
                        start: 'top 70%',
                        end: 'bottom 60%',
                        scrub: true,
                    },
                }
            );
        });

        imageRef.current.forEach((imageRef) => {
        gsap.fromTo(
            imageRef,
            { opacity: 0, x: 50},
            {
                opacity:1,
                x: 0,
                duration: 2,
                ease: 'power3.out',
                scrollTrigger: {
                    trigger: imageRef,
                    start: 'top 70%',
                    end: 'bottom 60%',
                    scrub: true,
                },
            }
        )});
    }, []);

    const setImageRef = (ref, index) => {
        imageRef.current[index] = ref;
    };

    return (
        <>
            <Navigation />
            <div className="about-page">
                {/* Introduction Section */}
                <section ref={introRef} className="about-section">
                    <img src="img/about1.webp" ref={(ref) => setImageRef(ref, 0)} alt="Empowering Businesses" className="about-image"/>
                    <h2>About Techveda</h2>
                    <p>
                        At Techveda, our mission is to empower businesses with state-of-the-art technology. 
                        We believe in a customer-centric approach and strive to provide innovative solutions 
                        that help companies reach new heights.
                    </p>
                </section>

                {/* Our Journey Section */}
                <section ref={journeyRef} className="about-section">
                    <img src="img/about2.webp" ref={(ref) => setImageRef(ref, 1)} alt="Our Journey" className="about-image"/>
                    <h2>Our Journey</h2>
                    <p>
                        Techveda began as a small startup with a big dream. Over the years, we have grown into 
                        a leader in SaaS, API integration, and DevOps, marked by milestones that showcase our 
                        commitment to quality and innovation. 
                    </p>
                </section>

                {/* Meet Our Team Section */}
                <section ref={teamRef} className="about-section">
                    <img src="img/about3.webp" alt="Our Team" ref={(ref) => setImageRef(ref, 2)} className="about-image"/>
                    <h2>Meet Our Team</h2>
                    <p>
                        Our team is the heart of Techveda. With experts in software development, DevOps, 
                        and cloud solutions, we bring together a wealth of experience and a passion for 
                        technology. Each member contributes unique skills that drive our success.
                    </p>
                </section>

                {/* Our Approach and Vision Section */}
                <section ref={visionRef} className="about-section">
                    <img src="img/about.webp" alt="Future Vision" ref={(ref) => setImageRef(ref, 3)} className="about-image"/>
                    <h2>Our Vision</h2>
                    <p>
                        Looking to the future, Techveda aims to lead in AI, machine learning, and cloud 
                        technologies, continually evolving to meet the needs of tomorrow’s businesses. 
                        Our vision is a tech-driven world where every business has the tools to thrive.
                    </p>
                </section>
            </div>
            <Footer />
        </>
    );
};

export default About;
