import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/home/Home.jsx';
import About from './components/about/About.jsx';
import ServicesPage from './components/service/ServicesPage.jsx';
import ContactPage from './components/contact/ContactPage.jsx';
import ServiceDetailPage from './components/service/ServiceDetailPage.jsx';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path='/about' element={<About/>} />
                <Route path='/services' element={<ServicesPage/>} />
                <Route path='/contact' element={<ContactPage/>} />
                <Route path="/services/:serviceId" element={<ServiceDetailPage />} />
            </Routes>
        </Router>
    );
}

export default App;