import React from 'react';
import { useParams } from 'react-router-dom';
import Navigation from '../common/navbar/Navigation';
import Footer from '../common/footer/Footer';
import './ServiceDetailPage.css';

const ServiceDetailPage = () => {
    const { serviceId } = useParams();
    const servicesData = {
        'custom-software-development': {
            title: 'Custom Software Development',
            sections: [
                {
                    type: 'paragraph',
                    subtitle: 'Understanding Your Needs',
                    content: 'Custom software development is our specialty. We understand that every business has unique requirements, and off-the-shelf solutions often lack the flexibility to address specific business goals. That’s why our team takes the time to understand your needs in depth and works collaboratively to create solutions that align perfectly with your vision. Our expertise covers a wide range of industries, enabling us to tailor functionalities, integrate seamlessly with existing systems, and ensure future scalability.',
                },
                {
                    type: 'image',
                    content: '/images/custom-software-1.jpg',
                    alt: 'Custom Software Development Process',
                },
                {
                    type: 'paragraph',
                    content: 'Our custom software services span from initial consultation and strategic planning to development, testing, and deployment. We follow agile methodologies, which means our clients are involved in every stage of development, from the design phase to implementation. This not only keeps our solutions aligned with client expectations but also helps us incorporate feedback swiftly, allowing continuous improvement and refinement until the final product meets your standards and our quality benchmarks.',
                },
                {
                    type: 'paragraph',
                    content: 'The benefits of custom software are substantial. Unlike generic solutions, a custom solution is designed to fit seamlessly into your existing operations, reducing the need for adjustments or workarounds. This means higher productivity, as your teams can work more efficiently with software that adapts to your workflows rather than the other way around. Custom solutions also offer better scalability; as your business grows, your software can evolve with it, incorporating new features or scaling resources to match increasing demands without major disruptions.',
                },
                {
                    type: 'image',
                    content: '/images/custom-software-2.jpg',
                    alt: 'Team Working on Custom Software Solution',
                },
                {
                    type: 'paragraph',
                    content: 'Our approach doesn’t end at deployment. We offer ongoing maintenance and support, ensuring that your software continues to function optimally even as technologies advance. Our team provides regular updates, security enhancements, and bug fixes, which means you won’t be left behind in today’s fast-paced digital world. Whether it’s adding new features or making adjustments to improve efficiency, our custom software development solutions grow with your business, providing lasting value.',
                },
                {
                    type: 'paragraph',
                    content: 'If you’re looking for a competitive advantage, custom software could be the answer. By implementing a solution that addresses your specific needs, you can streamline operations, improve customer satisfaction, and gain insights that drive strategic decision-making. Our clients have seen significant benefits from our custom software, from cost savings to increased operational efficiency. When you’re ready to take the next step, our team is here to guide you through the process and create a software solution that’s as unique as your business.',
                },
            ],
        },
        'cloud-solutions': {
            title: 'Cloud Solutions',
            sections: [
                {
                    type: 'paragraph',
                    content: 'Our cloud solutions are designed to provide businesses with the flexibility, scalability, and security they need in today’s data-driven world. With cloud-based infrastructure, you gain instant access to data and applications from anywhere, facilitating remote work, collaboration, and real-time data insights. We work with leading cloud providers to offer customized solutions tailored to your industry and specific business requirements, ensuring that your transition to the cloud is seamless and efficient.',
                },
                {
                    type: 'image',
                    content: '/images/cloud-solutions-1.jpg',
                    alt: 'Cloud Infrastructure Setup',
                },
                // Add additional paragraphs and images as needed
            ],
        },
        'inventory-management': {
            title: 'Inventory Management Systems',
            sections: [
                {
                    type: 'paragraph',
                    content: 'Inventory management is at the core of many businesses, and our systems are built to streamline every aspect of this critical function. Our inventory solutions provide real-time data tracking, helping you monitor stock levels, track orders, and manage supply chains more efficiently. By reducing manual tracking and minimizing errors, you can ensure that your inventory is always aligned with demand, reducing costs and enhancing customer satisfaction.',
                },
                {
                    type: 'image',
                    content: '/images/inventory-management-1.jpg',
                    alt: 'Real-time Inventory Tracking',
                },
                // Add additional paragraphs and images as needed
            ],
        },
        'business-loan-audit': {
            title: 'Business Loan Audit Systems',
            sections: [
                {
                    type: 'paragraph',
                    content: 'Loan audits are critical for both lenders and borrowers, ensuring transparency, accountability, and adherence to financial regulations. Our business loan audit systems are built to simplify and automate this complex process. With intuitive tools, automated workflows, and real-time reporting capabilities, you can ensure that audits are accurate, efficient, and compliant with industry standards. Our system provides auditors and managers with all the necessary data to make informed decisions while maintaining a clear audit trail.',
                },
                {
                    type: 'image',
                    content: '/images/loan-audit-1.jpg',
                    alt: 'Loan Audit Process Automation',
                },
                // Add additional paragraphs and images as needed
            ],
        },
        // Add more services as needed
    };
    
    const service = servicesData[serviceId];


    return (
        <>
            <Navigation />
            <div className="service-detail-page">
                <header className="service-detail-header">
                    <h1>{service.title}</h1>
                </header>
                <section className="service-content">
                    {service.sections.map((section, index) => {
                        if (section.type === 'paragraph') {
                            return (
                                <div key={index} className="service-paragraph">
                                    {section.subtitle && <h3 className="paragraph-subtitle">{section.subtitle}</h3>}
                                    <p>{section.content}</p>
                                </div>
                            );
                        } else if (section.type === 'image') {
                            return <img key={index} src={section.content} alt={section.alt} className="service-image" />;
                        }
                        return null;
                    })}
                </section>
            </div>
            <Footer />
        </>
    );
};

export default ServiceDetailPage;
