// src/components/Navbar.js
import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Navbar.css';

const Navigation = () => {
    const location = useLocation(); // Hook to get current path

    return (
        <Navbar expand="lg" className="navbar fixed-top">
            <div className="container">
                <Navbar.Brand as={Link} to="/" className="navbar-brand">
                    <div className="logo-img">
                        <img className='logo' src="/img/logo.webp" alt="" />
                    </div>
                    <div className="logo-name">
                        Techveda
                    </div>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                        <Nav.Link
                            as={Link}
                            to="/"
                            className={location.pathname === '/' ? 'active' : ''}
                        >
                            Home
                        </Nav.Link>
                        <Nav.Link
                            as={Link}
                            to="/about"
                            className={location.pathname === '/about' ? 'active' : ''}
                        >
                            About
                        </Nav.Link>
                        <Nav.Link
                            as={Link}
                            to="/services"
                            className={location.pathname === '/services' || location.pathname.startsWith("/services") ? 'active' : ''}
                        >
                            Services
                        </Nav.Link>
                        <Nav.Link
                            as={Link}
                            to="/contact"
                            className={location.pathname === '/contact' ? 'active' : ''}
                        >
                            Contact
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </div>
        </Navbar>
    );
};

export default Navigation;
