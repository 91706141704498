import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Home.css';
import Footer from '../common/footer/Footer';
import Navigation from '../common/navbar/Navigation';
import { Helmet } from 'react-helmet';

gsap.registerPlugin(ScrollTrigger);

const Home = () => {
    const heroRef = useRef(null);
    const featuresRef = useRef(null);
    const servicesRef = useRef(null);

    useEffect(() => {
        // Hero section animation on load
        gsap.fromTo(
            heroRef.current,
            { opacity: 0, y: -50 },
            { opacity: 1, y: 0, duration: 1.5, ease: 'power3.out' }
        );

        // Features section animation on scroll
        gsap.fromTo(
            featuresRef.current,
            { opacity: 0, x: -50 },
            {
                opacity: 1,
                x: 0,
                duration: 1,
                ease: 'power3.out',
                scrollTrigger: {
                    trigger: featuresRef.current,
                    start: 'top 80%',
                    end: 'bottom 60%',
                    scrub: true,
                },
            }
        );

        // Services section animation on scroll
        gsap.fromTo(
            servicesRef.current,
            { opacity: 0, x: 50 },
            {
                opacity: 1,
                x: 0,
                duration: 1,
                ease: 'power3.out',
                scrollTrigger: {
                    trigger: servicesRef.current,
                    start: 'top 80%',
                    end: 'bottom 60%',
                    scrub: true,
                },
            }
        );
    }, []);

    return (
        <>
            <Helmet>
                <meta property="og:image" content={`https://techveda.org.in/og-image.png`} />
                <meta property="og:image:alt" content="Techveda software solutions" />
                <meta property="og:image:width" content="1200" />  {/* Replace with your actual image width */}
                <meta property="og:image:height" content="630" />
            </Helmet>
            <Navigation/>
            <div className="container">
                {/* Hero Section */}
                <section ref={heroRef} className="hero-section section">
                    <h1>Welcome to Techveda</h1>
                    <p>Empowering businesses with innovative software solutions and cutting-edge technology.</p>
                    <button className="btn btn-primary">Discover More</button>
                </section>

                {/* Features Section */}
                <section ref={featuresRef} className="features-section section row">
                    <div className="col-md-4 text-center">
                        <h3>Expert Development</h3>
                        <p>Our experienced team builds reliable, high-quality software solutions tailored to your needs.</p>
                    </div>
                    <div className="col-md-4 text-center">
                        <h3>Scalable Solutions</h3>
                        <p>We design scalable platforms that grow with your business, ensuring long-term success.</p>
                    </div>
                    <div className="col-md-4 text-center">
                        <h3>Client-Centric Approach</h3>
                        <p>Our customer-first approach guarantees exceptional service and unmatched results.</p>
                    </div>
                </section>

                {/* Services Section */}
                <section ref={servicesRef} className="services-section section">
                    <h2>Our Services</h2>
                    <p className="lead">Explore our wide range of services designed to meet all your business technology needs.</p>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card p-3">
                                <h4>Web Development</h4>
                                <p>Responsive websites with user-friendly designs that perform seamlessly across devices.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card p-3">
                                <h4>Mobile App Development</h4>
                                <p>Custom-built mobile applications that offer high engagement and a smooth user experience.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card p-3">
                                <h4>Cloud Solutions</h4>
                                <p>Comprehensive cloud solutions that ensure data security, flexibility, and scalability.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer/>
        </>
    );
};

export default Home;
